import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'
import { ButtonWithRightArrow } from '../../atoms/buttons/LinkButtons'
import colors from '../../tokens/colors'

const Component = props => {
  return (
    <Container>
      <StyledLink to={'./interviews/' + props.id}>
        <div>
          <figure>
            <img src={props.image} alt={props.name} />
          </figure>
          <ButtonWithRightArrow fill={colors.cl_red} link={'interviews/' + props.id } >
            Read Mode
          </ButtonWithRightArrow>
        </div>
      </StyledLink>
      <TextContainer>
        <h4>{props.title}</h4>
        <h5>
          {props.name} / {props.name_en}
          <span>{props.position}</span>
        </h5>
        <p>{props.profile}</p>
      </TextContainer>
    </Container>
  )
}

const Container = styled.li`
  width: 47%;
  margin-bottom: 80px;
  letter-spacing: 0.04em;
  text-align: left;
  ${breakpoints.lessThan('pc')`
  width: 100%;
  margin-bottom: 60px;
`}
`

const StyledLink = styled(Link)`
  display: block;
  text-align: left;
  div {
    position: relative;
    margin-bottom: 24px;
    figure {
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        top: 0;
      }
    }
    a {
      margin-top: -50%;
    }
  }
`
const TextContainer = styled.div`
  h4 {
    margin-bottom: 20px;
    padding-bottom: 26px;
    font-size: 2.4rem;
    line-height: 36px;
    border-bottom: 4px solid ${colors.cl_red};
    ${breakpoints.lessThan('pc')`
    padding-bottom: 20px;
`}
  }
  h5 {
    margin-bottom: 18px;
    font-size: 1.8rem;
    line-height: 27px;
    span {
      display: block;
      margin-top: 10px;
      font-size: 1.4rem;
      color: ${colors.cl_txt};
    }
  }
  p {
    font-size: 1.4rem;
    line-height: 21px;
    color: ${colors.cl_txt};
  }
`

export default Component

import React from 'react'
import Layout from '../components/layout'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'
import Chart from '../components/organisms/FlowChartContainer'
import Works from '../components/organisms/VerticalContents'
import Career from '../components/modules/TitleAndImage'
import Interviews from '../components/organisms/Interviews'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

export const query = graphql`
  {
    microcmsWorksCareers {
      id
      title
      image {
        url
      }
    }
    allMicrocmsSteps {
      edges {
        node {
          id
          title
          text
          caption
        }
      }
    }
    allMicrocmsWorks {
      edges {
        node {
          id
          title
          image {
            url
          }
          copy
          text
          profile
          comment
        }
      }
    }
    microcmsCareerPath {
      id
      image {
        url
      }
      imaseSP {
        url
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="WORKS" />
    <KeyVisual
      title={data.microcmsWorksCareers.title}
      image={data.microcmsWorksCareers.image.url}
    />
    <Chart data={data.allMicrocmsSteps.edges} />
    <Works data={data.allMicrocmsWorks.edges} />
    <Career data={data.microcmsCareerPath} />
    <Interviews />
  </Layout>
)
export default IndexPage

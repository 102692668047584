import React from 'react'
import styled from 'styled-components'
import Content from '../modules/containers/VerticalHalfContents'
import breakpoints from '../breakpoints'
import { flex } from '../tokens/positionings'

const Component = ({ data }) => {
  const contents = []
  for (let content of data) {
    content = content.node
    contents.push(
      <Content
        title={content.title}
        text={content.text}
        image={content.image.url}
        copy={content.copy}
        profile={content.profile}
        comment={content.comment}
      />
    )
  }
  return <Section>{contents}</Section>
}

const Section = styled.ul`
  max-width: 980px;
  margin: 0 auto;
  ${flex('space-between')};
  ${breakpoints.lessThan('pc')`
  padding: 20px;
`}
`
export default Component

import React from 'react'
import styled from 'styled-components'
import HeadingWithUnderbar from '../atoms/HeadingWithUnderbar'
import colors from '../tokens/colors'
import breakpoints from '../breakpoints'

const Component = ({ data }) => {
  return (
    <Wrapper>
      <HeadingWithUnderbar
        title="Career Path"
        subTitle="キャリアパス"
        margin={80}
      />
      <figure>
        <img src={data.image.url} className="pc" alt="キャリアパス" />
        <img src={data.imaseSP.url} className="sp" alt="キャリアパス" />
      </figure>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  border-top: 10px solid ${colors.cl_red};
  padding: 80px 20px;
  text-align: center;
  figure {
    max-width: 980px;
    margin: 0 auto;
    svg {
      margin: 0 auto;
    }
  }
  .sp {
    display: none;
  }
  ${breakpoints.lessThan('pc')`
.pc{
  display: none;
}
.sp{
  display: block;
}
`}
`

export default Component

import React from 'react'
import styled from 'styled-components'
import HeadingWithUnderbar from '../atoms/HeadingWithUnderbar'
import Interview from '../modules/containers/Interview'
import breakpoints from '../breakpoints'
import { WidthRestructor } from '../tokens/sizing'
import { flex } from '../tokens/positionings'
import { useStaticQuery, graphql } from 'gatsby'
const Component = () => {
  const data = useStaticQuery(
    graphql`
      {
        allMicrocmsInterviews {
          edges {
            node {
              id
              title
              name
              name_en
              position
              image {
                url
              }
              article_title_1
              article_text_1
              article_title_2
              article_text_2
              article_title_3
              article_text_3
            }
          }
        }
      }
    `
  )
  const contents = []
  for (let content of data.allMicrocmsInterviews.edges) {
    content = content.node
    contents.push(
      <Interview
        title={content.title}
        text={content.text}
        image={content.image.url}
        name={content.name}
        name_en={content.name_en}
        profile={content.profile}
        position={content.position}
        id={content.id}
      />
    )
  }
  return (
    <Section>
      <HeadingWithUnderbar
        title="Interviews"
        subTitle="社員インタビュー
      "
        margin={80}
      />
      <WidthRestructor>
        <Container>{contents}</Container>
      </WidthRestructor>
    </Section>
  )
}

const Section = styled.section`
  text-align: center;
  width: 100%;
  padding: 60px 40px;
  border-top: 10px solid #da6862;
  border-bottom: 10px solid #da6862;
  ${breakpoints.lessThan('pc')`
  padding: 20px;
`}
`

const Container = styled.ul`
  ${flex('space-between')}
`

export default Component

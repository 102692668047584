import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'
import colors from '../../tokens/colors'
import { fixRatio } from '../../tokens/sizing'
import { HeadingWithLeftbar } from '../../tokens/fontStyles'

const Component = props => {
  return (
    <Container>
      <h4>{props.title}</h4>
      <p>{props.text}</p>
      <figure>
        <img src={props.image} alt={props.title} />
      </figure>
      <h5>
        {props.copy}
        <span>{props.profile}</span>
      </h5>
      <p>{props.comment}</p>
    </Container>
  )
}

const Container = styled.li`
  width: 47%;
  margin-bottom: 80px;
  ${breakpoints.lessThan('pc')`
  width: 100%;
  margin-bottom: 40px;
`}
  h4 {
    margin-bottom: 20px;
    ${HeadingWithLeftbar};
    ${breakpoints.lessThan('pc')`
			margin-bottom: 12px;
  `}
  }
  &:last-of-type {
    margin-bottom: 80px;
    ${breakpoints.lessThan('pc')`
    margin-bottom: 60px;
  `}
  }
  p {
    height: 128px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 180%;
    ${breakpoints.lessThan('pc')`
    height: auto;
    font-size: 1.4rem;
  `}
  }
  figure {
    margin-bottom: 20px;
    ${fixRatio('9/16')}
    ${breakpoints.lessThan('pc')`
    margin-bottom: 10px;
  `}
  img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  h5 {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
    ${breakpoints.lessThan('pc')`
    font-size: 1.8rem;
    margin-bottom: 10px;
  `}
    span {
      display: block;
      margin-top: 10px;
      color: ${colors.cl_txt};
      font-size: 1.6rem;
      ${breakpoints.lessThan('pc')`
      font-size: 1.4rem;
      margin-bottom: 10px;
    `}
    }
  }
`

export default Component

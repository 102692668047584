import React from 'react'
import HeadingWithUnderbar from '../atoms/HeadingWithUnderbar'
import FlowChart from '../modules/containers/FlowChart'
import colors from '../tokens/colors'
import styled from 'styled-components'
import breakpoints from '../breakpoints'

const Component = ({ data }) => {
  const contents = []
  for (let content of data) {
    content = content.node
    contents.push(
      <FlowChart
        title={content.title}
        text={content.text}
        caption={content.caption}
      />
    )
  }
  return (
    <Section>
      <HeadingWithUnderbar title="About Work" subTitle="仕事内容" margin={80} />
      <FlowWrapper>{contents}</FlowWrapper>
    </Section>
  )
}
const Section = styled.section`
  width: 100%;
  padding: 80px 40px 0;
  border-top: 10px solid ${colors.cl_red};
  margin-bottom: 80px;
  ${breakpoints.lessThan('pc')`
    margin-bottom: 60px;
    padding: 80px 20px 0;
  `}
`
const FlowWrapper = styled.div`
  margin-bottom: 80px;
  max-width: 980px;
  margin: 0 auto;
  ${breakpoints.lessThan('pc')`
    margin-bottom: 60px;
  `}
`

export default Component

import React from 'react'
import styled from 'styled-components'
import { flex, centerize } from '../../tokens/positionings'
import breakpoints from '../../breakpoints'
import colors from '../../tokens/colors'

const Component = props => {
  return (
    <Flow>
      <dt>{props.title}</dt>
      <dd>
        {props.text}
        <span>{props.caption}</span>
      </dd>
    </Flow>
  )
}

const Flow = styled.dl`
  margin-bottom: 30px;
  ${flex('flex-start')};
  ${breakpoints.lessThan('pc')`
    margin-bottom: 20px;
  `}
  dt {
    ${centerize};
    max-width: 160px;
    width: 30%;
    margin-right: 24px;
    padding: 20px 24px;
    background: ${colors.cl_red};
    border-radius: 3px;
    font-size: 2.4rem;
    color: ${colors.cl_white};
    text-align: center;
    ${breakpoints.lessThan('pc')`
      margin-right: 12px;
      font-size: 1.4rem;
    `}
    span {
      display: block;
      font-size: 1.3rem;
    }
  }
  dd {
    width: calc(100% - 30% - 24px);
    font-size: 1.8rem;
    line-height: 180%;
    ${flex('flex-start')};
    align-items: center;
    ${breakpoints.lessThan('pc')`
      font-size: 1.4rem;
      width: calc(100% - 30% - 12px);
    `}
    span {
      margin-top: 10px;
      color: ${colors.cl_txt};
    }
  }
`
export default Component

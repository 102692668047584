import React from 'react'
import styled from 'styled-components'
import { centerize } from '../../tokens/positionings'
import { PageTitle } from '../../tokens/fontStyles'
import { fixRatio } from '../../tokens/sizing'
import breakpoints from '../../breakpoints'
import colors from '../../tokens/colors'

const Component = ({ image, title, ceo }) => {
  return (
    <KV image={image} ceo={ceo}>
      <h1>{title}</h1>
    </KV>
  )
}

const KV = styled.div`
  background: url(${props => props.image}) center / cover no-repeat;
  min-height: 480px;
  ${centerize};
  ${fixRatio('47/130')};
  ${breakpoints.lessThan('pc')`
      background: url(${props => props.image}) center / cover no-repeat;
  `}
  h1 {
    ${PageTitle};
    color: ${colors.cl_white};
    width: 100%;
  }
`

export default Component
